<template>
  <ValidationProvider :name="$attrs.name" :rules="rules" v-slot="{ errors }">
    <v-text-field
        v-model="innerValue"
        :error-messages="errors"
        v-bind="$attrs"
        v-on="$listeners"
        :type="type"
        :step="step"
        :min="min"
        :max="max"
        :class="classes"
        :hide-details="hideDetails"
        :prepend-icon="prependIcon"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </v-text-field>
  </ValidationProvider>
</template>

<script>
import {ValidationProvider} from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },

  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null
    },
    type: {
      type: [String],
      default: "text"
    },
    step: {
      type: [String],
      default: null
    },
    min: {
      type: [String],
      default: null
    },
    max: {
      type: [String],
      default: null
    },
    classes: {
      type: [String],
      default: ""
    },
    prependIcon: {
      type: [String],
      default: ""
    },
    hideDetails: {
      type: [Boolean],
      default: false
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
